import { render, staticRenderFns } from "./BotWlcmHomeCampaignSettingsCard.vue?vue&type=template&id=32778075"
import script from "./BotWlcmHomeCampaignSettingsCard.vue?vue&type=script&lang=ts"
export * from "./BotWlcmHomeCampaignSettingsCard.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports