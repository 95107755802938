
import { WlcmHomeCampaign, WlcmHomeCampaignImage } from 'ignite360-core';
import { Component, Prop, Vue } from 'vue-property-decorator';
import AppMultiFileDropzone from '@/components/ui/AppMultiFileDropzone.vue';
import { S3_BUCKET, S3_REGION } from '@/api';
import { WlcmHomeApi } from '@/api/WlcmHomeApi';
import draggable from 'vuedraggable'

@Component({
  name: 'bot-wlcm-home-campaign-images-card',
  components: {
    AppMultiFileDropzone,
    draggable,
  },
})
export default class BotWlcmHomeCampaignImagesCard extends Vue {
  @Prop({ required: true, type: Object })
  readonly campaign!: WlcmHomeCampaign;

  drag = false;

  fileUploaded(file: any, response: any) {
    this.$emit('images-uploaded');
  }

  imageUrl(image: WlcmHomeCampaignImage) {
    return `https://${S3_BUCKET}.s3.${S3_REGION}.amazonaws.com/bots/${this.$route.params.id}/wlcm-home/campaigns/${this.campaign.id}/images/${image.id}.${image.extension}`;
  }

  async deleteImage(image: WlcmHomeCampaignImage) {
    try {
      await WlcmHomeApi.deleteBotWlcmHomeCampaignImage(this.$route.params.id, this.campaign.id, image.id);
      this.$emit('image-deleted');
    } catch (error) {
      console.error('Error deleting image', error);
    }
  }

  async updateImagesOrder() {
    try {
      const updatedOrder = this.campaign.images.map((image, index) => ({
        id: image.id,
        order: index,
      }));
      
      await WlcmHomeApi.updateBotWlcmHomeCampaignImagesOrder(
        this.$route.params.id,
        this.campaign.id,
        updatedOrder
      );
      this.$emit('images-reordered');
    } catch (error) {
      console.error('Error updating image order ', error);
    }
  }

  async toggleHeroImage(image: WlcmHomeCampaignImage) {
    try {
      await WlcmHomeApi.updateImageHeroStatus(
        this.$route.params.id,
        this.campaign.id,
        image.id,
        !image.heroImage
      );
      // Update the local state
      image.heroImage = !image.heroImage;
      // If this image is now the hero, set all others to false
      if (image.heroImage) {
        this.campaign.images.forEach(img => {
          if (img.id !== image.id) {
            img.heroImage = false;
          }
        });
      }
      this.$emit('hero-image-updated');
    } catch (error) {
      console.error('Error updating hero image status', error);
    }
  }
}
