
import { WlcmHomeApi } from '@/api/WlcmHomeApi';
import { GENERIC_ERROR_DESCRIPTION } from '@/constants';
import { AuthMixin } from '@/mixins/AuthMixin';
import { AppRoute } from '@/router/routes';
import { WlcmHomeCampaign } from 'ignite360-core';
import { mixins } from 'vue-class-component';
import { Component, Vue } from 'vue-property-decorator';
  import BotWlcmHomeCampaignSettingsCard from '@/components/bot/wlcm-home/BotWlcmHomeCampaignSettingsCard.vue';
import BotWlcmHomeCampaignImagesCard from '@/components/bot/wlcm-home/BotWlcmHomeCampaignImagesCard.vue';
@Component({
  name: 'project-wlcm-home-campaign-overview',
  components: { BotWlcmHomeCampaignSettingsCard, BotWlcmHomeCampaignImagesCard },
})
export default class ProjectWlcmHomeCampaignOverview extends mixins(AuthMixin) {

  campaign: WlcmHomeCampaign | null = null;

  isDeleting = false;
  async mounted() {

    await this.loadCampaign();
  }

  async loadCampaign() {
   try {
         this.campaign = await WlcmHomeApi.getBotWlcmHomeCampaign(this.$route.params.id, this.$route.params.campaignId);

    } catch (error) {
      console.error("Can't load campaign", error);
    }
  }
  async requestDeleteConfirmation() {
    if (!this.campaign) return;
    const confirmed = confirm(`Are you sure that you want to delete this campaign?`);
    if (confirmed) {
      this.isDeleting = true;
      try {
        this.$notify.success('Successfully deleted campaign');
        await WlcmHomeApi.deleteBotWlcmHomeCampaign(this.$route.params.id, this.$route.params.campaignId);
        await this.$router.replace({ name: AppRoute.ProjectWlcmHomeCampaigns });
      } catch (e) {
        this.$notify.error({
          title: 'Deleting the campaign failed',
          description: GENERIC_ERROR_DESCRIPTION,
        });
      } finally {
        this.isDeleting = false;
      }
    }
  }

}
