
import { GENERIC_ERROR_DESCRIPTION } from '@/constants';
import { WlcmHomeCampaignSettings } from '@/types';
import isEqual from 'fast-deep-equal/es6';
import { WlcmHomeCampaign } from 'ignite360-core';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import BotWlcmHomeCampaignForm from '@/components/bot/wlcm-home/BotWlcmHomeCampaignForm.vue';
import { WlcmHomeApi } from '@/api/WlcmHomeApi';

@Component({
  name: 'bot-wlcm-home-campaign-settings-card',
  components: {
    BotWlcmHomeCampaignForm,
  },
})
export default class BotWlcmHomeCampaignSettingsCard extends Vue {
  @Prop({ required: true, type: Object })
  readonly campaign!: WlcmHomeCampaign;


  wlcmHomeCampaignSettings: WlcmHomeCampaignSettings = this.resetValue();

  isSubmitting = false;

  get settingsHaveChanged(): boolean {
    return !isEqual(this.wlcmHomeCampaignSettings, this.resetValue());
  }

  async onSubmit() {
    this.isSubmitting = true;
    try {
      const botId = this.$route.params.id;
      await WlcmHomeApi.updateBotWlcmHomeCampaign(botId, this.campaign.id, this.wlcmHomeCampaignSettings);
      this.$notify.success('Successfully updated settings');
    } catch (e) {
      this.$notify.error({
        title: 'Updating settings failed',
        description: GENERIC_ERROR_DESCRIPTION,
      });
    } finally {
      this.isSubmitting = false;
    }
  }

  @Watch('campaign', { immediate: true, deep: true })
  private reset() {
    this.wlcmHomeCampaignSettings = this.resetValue();
  }

  private resetValue(): WlcmHomeCampaignSettings {
    return {
      name: this.campaign.name,
      title: this.campaign.title,
      description: this.campaign.description,
      slug: this.campaign.slug,
      styles: this.campaign.styles,
      agent: this.campaign.agent,
      date: this.campaign.date
    };
  }
}
